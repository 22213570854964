import axios from 'axios'
import i18n from '@/i18n'
import Swipe from '@/libs/swipe'
import { TinyColor, readability } from '@ctrl/tinycolor'
import Defaults from '@/libs/defaults'
import { additionalIgnoreSelectors } from '@/libs/signly.config'

const BLOCK_SELECTORS = 'address,blockquote,button,th,dd,dl,dt,h1,h2,h3,h4,h5,h6,li,ol,p,pre,ul'
const INLINE_SELECTORS = 'a,abbr,acronym,caption,b,bdo,big,button,em,i,label,mark,q,quote,signly,small,span,strong,sub,sup,tt'

let http = null

class Signly {
  constructor ({ url }) {
    http = axios.create({
      baseURL: url,
      headers: { 'Content-Type': 'application/json' }
    })

    this.mouseEnterEventListenerList = []
    this.mouseLeaveEventListenerList = []
    this.swipeList = []
  }

  /* Signly REST API methods */

  async getPageData (uri) {
    console.info('[signly] getPageData')

    try {
      const { data: { page, config } } = await http.get('pages/search', { params: { uri: this._trimUri(uri) } })

      return { page, config }
    } catch (error) {
      return { error: error.response ? error.response.status : 500 }
    }
  }

  async requestTranslation (newPageData) {
    try {
      const { data: { page } } = await http.post('pages', { page: newPageData, source: 'browser' })
      return { page }
    } catch (error) {
      return { error: error.response ? error.response.status : 500 }
    }
  }

  async getVideoBlob (videoUrl) {
    try {
      const { data } = await axios({
        method: 'get',
        url: videoUrl,
        responseType: 'arraybuffer'
      })

      return new Blob([data], { type: 'video/mp4' })
    } catch (error) {
      return { error: error.response ? error.response.status : 500 }
    }
  }

  /* Signly Browser Client Methods */
  getElementsContainingBr (containerElements) {
    // Get all elements in the containerElements
    const allElements = containerElements.querySelectorAll('*')

    // Filter the elements to only those that contain a <br> tag as a descendant
    const elementsWithBr = Array.from(allElements).filter(element => {
      return Array.from(element.children).some(child => child.tagName === 'BR')
    })

    // Filter to get only those elements that do not contain a <signly> tag and are part of the BLOCK_SELECTORS
    return Array.from(elementsWithBr)
      .map(br => br.closest(BLOCK_SELECTORS + ',div')) // Get the closest parent that matches BLOCK_SELECTORS or div
      .filter((element, index, self) => element && !element.querySelector('signly') && self.indexOf(element) === index) // Filter out elements with <signly> and remove duplicates
  }

  wrapElementsSeparatedByBr (containerElements) {
    const selectedElements = this.getElementsContainingBr(containerElements)
    const signlyAppElement = document.getElementById('signly-app')

    selectedElements.forEach((selectedElement, index) => {
      if (signlyAppElement && signlyAppElement.contains(selectedElement)) {
        return // Skip elements inside #signly-app
      }

      const signlyData = this._getSignlyData(selectedElement)

      if (signlyData.ignore) {
        return // Skip elements with data-signly='{"ignore":true}'
      }

      const htmlContent = selectedElement.innerHTML

      // Split the content at the <br> tag
      const parts = htmlContent.split('<br>')

      // Wrap each part with <signly> tags
      for (let i = 0; i < parts.length; i++) {
        const cleanedText = this._cleanText(parts[i])
        if (cleanedText) {
          parts[i] = `<signly>${cleanedText}</signly>`
        } else {
          parts[i] = ''
        }
      }

      // Set the new HTML content of the element
      selectedElement.innerHTML = parts.join('<br>')
    })
  }

  _wrapNodes (nodes) {
    const signlyTag = document.createElement('signly')
    nodes.forEach(node => {
      signlyTag.appendChild(node)
    })

    return signlyTag
  }

  wrapTaglessElements (containerElements, tag) {
    const tagElements = Array.from(containerElements.querySelectorAll(tag))
    const signlyAppElement = document.getElementById('signly-app')

    tagElements.forEach((el) => {
      if (signlyAppElement && signlyAppElement.contains(el)) {
        return // Skip elements inside #signly-app
      }

      const signlyData = this._getSignlyData(el)

      if (signlyData.ignore) {
        return // Skip elements with data-signly='{"ignore":true}'
      }

      // We want to avoid having double signly tags
      const possibleInlineElements = INLINE_SELECTORS.replace(',signly', '').split(',')

      let containsTaglessText = false
      for (const node of el.childNodes) {
        if (node.nodeType === 3 && this._cleanText(node.textContent.trim()).length > 0) {
          containsTaglessText = true
          break
        }
      }

      // This ensures that only a combination of tagless text and other (or none) inline elements are considered for wrapping
      // e.g. <li><span>Text <span><a href="#">title</a></li> should not be wrapped
      if (!containsTaglessText) {
        return
      }

      const childNodes = Array.from(el.childNodes)

      let tempNodes = []

      childNodes.forEach(node => {
        if (node.nodeType === 3 && node.textContent.length > 0) { // Tagless text node
          tempNodes.push(node)
        } else if (possibleInlineElements.includes(node.nodeName.toLowerCase())) { // Inline element
          tempNodes.push(node)
        } else { // Other elements
          if (tempNodes.length > 0) {
            const wrappedNodes = this._wrapNodes(tempNodes)
            el.insertBefore(wrappedNodes, node)
            tempNodes = []
          }
        }
      })

      if (tempNodes.length > 0) {
        const inlineElements = tempNodes.filter(node => possibleInlineElements.includes(node.nodeName.toLowerCase()))
        // If there's only one element and that element is an inline element, don't wrap it
        if (tempNodes.length > 1 || inlineElements.length !== 1) {
          const wrappedNodes = this._wrapNodes(tempNodes)
          el.appendChild(wrappedNodes)
        }
      }
    })
  }

  getTextObjects (document) {
    const textObjects = []
    const bodyElements = document.querySelector('body') || document

    this.ignoreSignlyWidgetElements(bodyElements)

    this.wrapElementsSeparatedByBr(bodyElements)

    this.wrapTaglessElements(bodyElements, 'li')
    this.wrapTaglessElements(bodyElements, 'td')
    this.wrapTaglessElements(bodyElements, 'div')

    const selectedElements = Array.from(bodyElements.querySelectorAll(BLOCK_SELECTORS + ',' + INLINE_SELECTORS))

    selectedElements.forEach((selectedElement, index) => {
      if (this._isValidElement(selectedElement)) {
        const textSegment = this.getCleanText(selectedElement)
        textObjects.push({
          textElement: selectedElement,
          textSegment,
          textIndex: index
        })
      }
    })

    return textObjects
  }

  ignoreSignlyWidgetElements () {
    const signlyAppElement = document.getElementById('signly-app')

    if (signlyAppElement) {
      const signlyWidgetElements = Array.from(signlyAppElement.querySelectorAll('*'))

      signlyWidgetElements.forEach((signlyWidgetElement) => {
        this._setSignlyProp(signlyWidgetElement, 'ignore', true)
      })
    }

    additionalIgnoreSelectors.forEach(selector => {
      const elements = document.querySelectorAll(selector)
      elements.forEach(element => {
        this._setSignlyProp(element, 'ignore', true)
      })
    })
  }

  ignoreSpecificElements (ignoreSelectors) {
    const bodyElements = document.querySelector('body') || document
    if (ignoreSelectors && ignoreSelectors.length) {
      ignoreSelectors.forEach((ie) => {
        if (ie.value) {
          const ignoredElements = Array.from(bodyElements.querySelectorAll(ie.value))

          ignoredElements.forEach((ignoredElement) => {
            this._setSignlyProp(ignoredElement, 'ignore', true)

            if (ie.includeChildren) {
              const childElements = Array.from(ignoredElement.querySelectorAll('*'))

              childElements.forEach((childElement) => {
                this._setSignlyProp(childElement, 'ignore', true)
              })
            }
          })
        }
      })
    }
  }

  decorateTextElement (element, elementIndex, mediaBlock, playVideoCallback, textSegmentMessages) {
    if (this._isIgnoredElement(element)) {
      return
    }

    this._setSignlyProp(element, 'index', elementIndex)

    const mouseEnterEventListener = this.handleMouseEnter.bind(this, element, mediaBlock, playVideoCallback, elementIndex, textSegmentMessages)
    const mouseLeaveEventListener = this.handleMouseLeave.bind(this)

    this.mouseEnterEventListenerList.push({ el: element, evt: mouseEnterEventListener })
    this.mouseLeaveEventListenerList.push({ el: element, evt: mouseLeaveEventListener })

    element.addEventListener('mouseenter', mouseEnterEventListener)
    element.addEventListener('mouseleave', mouseLeaveEventListener)

    // element.addEventListener('mouseenter', this.handleMouseEnter(element, mediaBlock, playVideoCallback, elementIndex))
    // element.addEventListener('mouseleave', this.handleMouseLeave)

    if (mediaBlock && mediaBlock.status === 'translated' && mediaBlock.video && mediaBlock.video.uri) {
      this.swipeList.push(new Swipe(
        mediaBlock.video.uri,
        element,
        playVideoCallback,
        elementIndex
      ))
    } else {
      this.swipeList.push(new Swipe(
        textSegmentMessages.untranslatedTextSegmentVideoUrl,
        element,
        playVideoCallback
      ))
    }
  }

  removeDataSignlyAttributes () {
    const bodyElements = document.querySelector('body') || document
    const selectedElements = Array.from(bodyElements.querySelectorAll('*')).filter(element => element.hasAttribute('data-signly'))

    selectedElements.forEach((selectedElement, index) => {
      selectedElement.removeAttribute('data-signly')
    })

    const signlyElements = Array.from(bodyElements.querySelectorAll('signly'))

    signlyElements.forEach((signlyElement) => {
      while (signlyElement.firstChild) {
        signlyElement.parentNode.insertBefore(signlyElement.firstChild, signlyElement)
      }
      signlyElement.parentNode.removeChild(signlyElement)
    })

    this.mouseEnterEventListenerList.forEach(meel => meel.el.removeEventListener('mouseenter', meel.evt))
    this.mouseLeaveEventListenerList.forEach(mlel => mlel.el.removeEventListener('mouseleave', mlel.evt))

    this.swipeList.forEach(s => {
      s.removeEventListeners()
    })
  }

  handleMouseEnter (element, mediaBlock, playVideoCallback, elementIndex, textSegmentMessages) {
    if (element.id === 'signly-button') {
      return
    }

    const buttonElement = document.getElementById('signly-button')
    const borderElement = document.getElementById('signly-border')

    const translatedSignIconElement = document.getElementById('sign-icon')

    const buttonWidth = Defaults.textSegment.playButtonWidth
    const elementWidth = element.offsetWidth
    const elementHeight = element.offsetHeight
    const elementLeft = this.getOffset(element).left
    const elementTop = this.getOffset(element).top
    const borderOffset = Defaults.textSegment.borderWidth

    const elementOutlineOffset = window.getComputedStyle(element).outlineOffset
    const elementOutlineOffsetNumber = Number(window.getComputedStyle(element).outlineOffset.slice(0, -2))
    let buttonLeft = elementLeft + elementWidth + borderOffset
    let buttonLeftAdjust = -2

    if (elementLeft + elementWidth + buttonWidth + (2 * borderOffset) > window.innerWidth) {
      buttonLeft -= buttonWidth
      buttonLeftAdjust = borderOffset
    }

    Object.assign(borderElement.style, {
      width: elementWidth + 'px',
      height: elementHeight + 'px',
      left: elementLeft + 'px',
      top: elementTop + 'px',
      display: 'block',
      outlineOffset: elementOutlineOffset
    })

    Object.assign(buttonElement.style, {
      height: elementHeight + (2 * borderOffset) + (2 * elementOutlineOffsetNumber) + 'px',
      left: buttonLeft - borderOffset + (elementOutlineOffsetNumber < 0 && elementOutlineOffsetNumber) + buttonLeftAdjust + 'px',
      top: elementTop - borderOffset - elementOutlineOffsetNumber + 'px',
      display: 'block'
    })

    const rect = element.getBoundingClientRect()
    const translatedSingIconPathElement = document.getElementById('sign-icon-path')

    let elementColor = window.getComputedStyle(element) ? window.getComputedStyle(element).color : 'rgb(0, 0, 0)'
    const wrapperColor = new TinyColor(elementColor)

    wrapperColor.setAlpha(1)

    if (wrapperColor.getBrightness() < 10) {
      elementColor = wrapperColor.lighten()
    }

    Object.assign(buttonElement.style, { backgroundColor: elementColor })
    Object.assign(borderElement.style, { outlineColor: elementColor })

    let iconElementColor = this._getBackgroundColor(element)
    const contrastRatio = readability(elementColor, iconElementColor)

    // Makes sure the contrast ratio between button and icon is at least 3:1
    if (iconElementColor && (contrastRatio >= Defaults.textSegment.minContrastRatio)) {
      translatedSingIconPathElement.setAttribute('fill', iconElementColor)
    } else {
      iconElementColor = this._getContrastingIconColor(wrapperColor, iconElementColor)

      translatedSingIconPathElement.setAttribute('fill', iconElementColor)
    }

    const elementAndParentBackgroundRatio = readability(this._getBackgroundColor(element), this._getBackgroundColor(element.parentElement))

    // If the background of the element and it's parent are not contrasting enough reverse colors
    if (elementOutlineOffsetNumber >= 0 && (elementAndParentBackgroundRatio >= Defaults.textSegment.minContrastRatio)) {
      Object.assign(buttonElement.style, { backgroundColor: iconElementColor })
      Object.assign(borderElement.style, { outlineColor: iconElementColor })

      translatedSingIconPathElement.setAttribute('fill', elementColor)
    }

    if (this._hasTranslatedVideo(mediaBlock)) {
      buttonElement.onclick = this.handlePlayButtonClick(playVideoCallback, mediaBlock.video.uri, element, elementIndex)
      buttonElement.setAttribute('title', textSegmentMessages.playTranslation)
      buttonElement.setAttribute('aria-label', textSegmentMessages.playTranslation)
    } else {
      buttonElement.onclick = this.handlePlayButtonClick(playVideoCallback, textSegmentMessages.untranslatedTextSegmentVideoUrl, element)
      buttonElement.setAttribute('title', textSegmentMessages.contentBeingTranslated)
      buttonElement.setAttribute('aria-label', textSegmentMessages.contentBeingTranslated)
    }

    const newSize = rect.height < Defaults.textSegment.signIconLimit
      ? rect.height - Defaults.textSegment.signIconSizeDecrease + 'px'
      : Defaults.textSegment.signIconSize + 'px'

    translatedSignIconElement.setAttribute('height', newSize)
    translatedSignIconElement.setAttribute('width', newSize)

    buttonElement.addEventListener('mouseleave', this.handleMouseLeave)
  }

  handlePlayButtonClick (playVideoCallback, videoUri, element, elementIndex) {
    return (event) => {
      event.preventDefault()
      playVideoCallback({
        videoUrl: videoUri,
        element,
        elementIndex
      })
    }
  }

  handleMouseLeave (event) {
    const target = event.toElement || event.relatedTarget || event.target
    if (target.id !== 'signly-button') {
      const buttonElement = document.getElementById('signly-button')
      const borderElement = document.getElementById('signly-border')

      buttonElement.removeEventListener('click', this.handlePlayButtonClick)
      buttonElement.removeEventListener('mouseleave', this.handleMouseLeave)

      Object.assign(buttonElement.style, { display: 'none' })
      Object.assign(borderElement.style, { display: 'none' })
    }
  }

  getElementBySignlyIndex (index) {
    const signlyElements = Array.from(document.querySelectorAll('[data-signly] '))

    if (!signlyElements) {
      return null
    }

    return signlyElements.find(se => this._getSignlyData(se).index === index)
  }

  getOffset (el) {
    const rect = el.getBoundingClientRect()
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
      height: rect.bottom - rect.top,
      width: rect.right - rect.left
    }
  }

  getDimensions (el) {
    const style = window.getComputedStyle(el)
    return {
      height: parseInt(style.height),
      width: parseInt(style.width)
    }
  }

  _getContrastingIconColor (wrapperColor, iconElementColor) {
    let iconColor = new TinyColor(iconElementColor)
    iconColor.setAlpha(1)

    let darkenLightenAmount = 1

    while ((readability(wrapperColor.toString(), iconColor.toString()) <= Defaults.textSegment.minContrastRatio) && (darkenLightenAmount < 100)) {
      iconColor = wrapperColor.isLight() ? iconColor.darken(darkenLightenAmount) : iconColor.lighten(darkenLightenAmount)
      darkenLightenAmount += 1
    }

    return iconColor.toString()
  }

  /* Pseudo private methods */
  _getBackgroundColor (element) {
    const transparent = 'rgba(0, 0, 0, 0)'
    const color = window.getComputedStyle(element).backgroundColor
    if (color !== transparent) {
      return color
    }

    let parent = element.parentElement
    while (parent) {
      const parentColor = window.getComputedStyle(parent).backgroundColor
      if (parentColor !== transparent) {
        return parentColor
      }
      parent = parent.parentElement
    }

    return undefined
  }

  _hasTranslatedVideo (mediaBlock) {
    return !!(mediaBlock && mediaBlock.status === 'translated' && mediaBlock.video && mediaBlock.video.uri)
  }

  _background (element) {
    const transparent = 'rgba(0, 0, 0, 0)'

    if (!element) {
      return transparent
    }

    const color = getComputedStyle(element).backgroundColor
    const colorArray = color.replace(/ /g, '').slice(4, -1).split(',').map((e) => parseInt(e))

    if (color === transparent) {
      return this._background(element.parentElement)
    } else {
      return colorArray
    }
  }

  _luminance (r, g, b) {
    const a = [r, g, b].map(function (v) {
      v /= 255
      return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
    })
    return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
  }

  _contrast (rgb1, rgb2) {
    const lum1 = this._luminance(rgb1[0], rgb1[1], rgb1[2])
    const lum2 = this._luminance(rgb2[0], rgb2[1], rgb2[2])
    const brightest = Math.max(lum1, lum2)
    const darkest = Math.min(lum1, lum2)
    return (brightest + 0.05) / (darkest + 0.05)
  }

  _lightness (rgb) {
    const array = rgb.replace(/ /g, '').slice(4, -1).split(',').map((e) => parseInt(e))
    const high = Math.max(...array)
    const low = Math.min(...array)

    return Math.round((high + low) / 2 / 255 * 100)
  }

  _setSignlyProp (element, propName, propValue) {
    const signlyData = this._getSignlyData(element)
    signlyData[propName] = propValue

    element.setAttribute('data-signly', JSON.stringify(signlyData))
  }

  _getMargin (el) {
    const style = getComputedStyle(el)

    return {
      top: parseInt(style.marginTop),
      right: parseInt(style.marginRight),
      bottom: parseInt(style.marginBottom),
      left: parseInt(style.marginLeft)
    }
  }

  _getOuterWidth (el) {
    let width = el.offsetWidth
    const style = getComputedStyle(el)

    width += parseInt(style.marginLeft) + parseInt(style.marginRight)
    return width
  }

  _getOuterHeight (el) {
    let height = el.offsetHeight
    const style = getComputedStyle(el)

    height += parseInt(style.marginTop) + parseInt(style.marginBottom)
    return height
  }

  _trimUri (uri) {
    const noParamsUrl = uri.split('?')[0]
    return noParamsUrl.split('#')[0]
  }

  _getSignlyProp (element, propName) {
    const signlyData = element.getAttribute('data-signly')

    if (signlyData) {
      const parsedSignlyData = JSON.parse(signlyData)
      return parsedSignlyData[propName]
    }

    return {}
  }

  _getSignlyData (element) {
    const signlyData = element.getAttribute('data-signly')

    return signlyData ? JSON.parse(signlyData) : {}
  }

  _isIgnoredElement (element) {
    const elementDatasetSignly = element.dataset.signly

    if (elementDatasetSignly) {
      const signlyData = JSON.parse(elementDatasetSignly)
      return signlyData.ignore
    }

    return false
  }

  _isNotEmptyTextNode (element) {
    if (element.nodeType === 3 && element.nodeName === '#text') {
      return element.nodeValue.trim()
    } else if (element.nodeType === 1) {
      if (INLINE_SELECTORS.split(',').includes(element.nodeName.toLowerCase())) {
        return element.innerText
      }
      return false
    } else {
      return false
    }
  }

  _cleanText (text) {
    return text
      .replace(/\u00a0/g, ' ')
      .replace(/(\r\n|\n|\r|\\n)/gm, ' ')
      .replace(/\s\s+/g, ' ')
      .replace(' .', '.')
      .trim()
  }

  _elementText (element) {
    return element.innerText || element.textContent
  }

  getCleanText (element) {
    if (!element.querySelectorAll) {
      return this._elementText(element)
    }

    if (element.classList.contains('sr-only')) {
      return ''
    }

    let elementText = this._elementText(element)
    const spanSrOnlyElements = element.querySelectorAll('.sr-only')

    spanSrOnlyElements.forEach(el => {
      elementText = elementText.replace(this._elementText(el), '')
    })

    return this._cleanText(elementText)
  }

  _isValidElement (element) {
    const elemenText = this.getCleanText(element)
    const signlyAppElement = document.getElementById('signly-app')

    if (signlyAppElement && signlyAppElement.contains(element)) {
      return false
    }

    if (!elemenText || this._isIgnoredElement(element)) {
      return false
    }

    const innerElements = element.querySelectorAll('*')

    if (innerElements.length === 0) {
      return true
    } else {
      let hasItsOwnContent = false
      // eslint-disable-next-line no-unused-vars
      let hasBrTags = false
      const childrenNames = []

      const nodes = element.childNodes
      nodes.forEach(childNode => {
        if (this._isNotEmptyTextNode(childNode)) {
          hasItsOwnContent = true
        }

        if (childNode.tagName) {
          childrenNames.push(childNode.tagName)

          if (childNode.nodeName === 'BR') {
            hasBrTags = true
          }
        }
      })

      // If there are no main selectors inside the element - e.g. for the element <li><span>Text<span><h3>Title<h3></li>
      // I want to have a separate play button for 'Text' and 'Title'
      innerElements.forEach(innerElement => {
        if (BLOCK_SELECTORS.split(',').map(s => s.toUpperCase()).includes(innerElement.nodeName)) {
          hasItsOwnContent = false
        }
      })

      // If element has children with BR tags check if there are inner elements that might have their own text
      if (hasBrTags) {
        const containsInlineElements = INLINE_SELECTORS.split(',')
          .map(s => s.toUpperCase())
          .some(e => childrenNames.includes(e))

        if (containsInlineElements) {
          hasItsOwnContent = false
        }
      }

      if (hasItsOwnContent) {
        innerElements.forEach(innerElement => {
          innerElement.setAttribute('data-signly', '{"ignore": true}')
        })
      }

      return hasItsOwnContent
    }
  }
}

// Export the class and an instance
const defaultSignly = new Signly({
  url: i18n.global.t('signlyServer.baseUrl') || 'http://localhost:3030/api/v1/public'
})

export { Signly, defaultSignly }
export default defaultSignly

<template>
    <div
        id="signly-app"
        ref="signlyApp"
        role="dialog"
        :aria-label="$t('app.title')"
        aria-hidden="false"
        :data-signly-version="signlyVersion"
        :style="widgetStyles"
    >
      <transition name="slide-vertical" mode="out-in">
        <div v-show="showContainer" id="signly-container" ref="widget" key="signly-container" draggable="true">
          <SignlyHeader
              v-model:video-width="videoWidth"
              v-model:show-menu="showMenu"
              @closeWindow="onCloseClick"
          />

          <div id="signly-body">
            <SignlyAlert
                v-if="alert && alert.message"
                :video-width="videoWidth"
                :message="alert.message"
                :action="alert.action"
                :icon="alert.icon"
                :alert="alert"
                v-model:requesting-translation="requestingTranslation"
                @retryConnection="retryConnection"
            />

            <SignlyVideo
              :video-width="videoWidth"
              :video-url="videoUrl"
              :show-container="showContainer"
              v-model:playing="playingVideo"
              @videoResize="onVideoResize"
              @videoStopped="cleanPlayingBorder"
            />
          </div>
        </div>
      </transition>

      <transition name="slide-vertical" mode="out-in">
        <button
            v-show="!showContainer"
            id="signly-logo-button"
            key="signly-logo-button"
            ref="logoButton"
            @click="onLogoClick"
            :style="{ 'min-height': logoHeight, 'min-width': logoHeight }"
            tabindex="0"
            :title="$t('logoButton.title')"
            :aria-label="$t('logoButton.title')"
            draggable="true"
        >
            <SignlyLogo image="sign-icon-white.svg" :video-width="videoWidth" />
        </button>
      </transition>
    </div>
</template>

<script>
import packageJSON from '../package.json'
import signly from '@/libs/signly'
import Defaults from '@/libs/defaults'
import SignlyHeader from '@/components/SignlyHeader'
import SignlyLogo from '@/components/SignlyLogo'
import SignlyVideo from '@/components/SignlyVideo'
import { video } from '@/components/mixins/video'
import SignlyAlert from '@/components/SignlyAlert'

export default {
  name: 'SignlyApp',
  mixins: [video],
  components: {
    SignlyAlert,
    SignlyHeader,
    SignlyLogo,
    SignlyVideo
  },
  data () {
    const defaultAlert = {}
    const persistedVideoWidth = parseInt(localStorage.getItem('signlyVideoWidth'), 10) || Defaults.video.INITIAL_WIDTH

    const showContainer = localStorage.getItem('showSignly') === 'true'
    const signlyLoadCounter = JSON.parse(localStorage.getItem('signlyLoadCounter'))

    return {
      signlyVersion: packageJSON.version,
      showHeader: true,
      showContainer,
      showMenu: false,
      videoWidth: persistedVideoWidth || Defaults.video.INITIAL_WIDTH,
      headerHeight: 0,
      window: {
        width: 0,
        height: 0
      },
      alert: null,
      videoUrl: defaultAlert.videoUrl,
      playingVideo: false,
      page: null,
      config: null,
      textObjects: [],
      mediaBlocks: [],
      textSegments: [],
      currentTextElement: null,
      error: null,
      requestingTranslation: false,
      translationRequestSent: false,
      currentMediaBlockIndex: null,
      mappedMediaBlocks: {},
      signlyLoadCounter,
      currentPageUrl: null,
      processDOMChange: null,
      observer: null
    }
  },
  created () {
    window.addEventListener('popstate', this.handleUrlChange)
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  async mounted () {
    window.capturePageText = () => {
      this.setPageData()
    }

    if (document.readyState === 'loading') {
      document.addEventListener('DOMContentLoaded', this.onDOMContentLoaded)
    } else {
      await this.onDOMContentLoaded()
    }
    this.drag(this.$refs.widget)
  },
  unmounted () {
    window.removeEventListener('popstate', this.handleUrlChange)
    window.removeEventListener('resize', this.handleResize)
    document.removeEventListener('DOMContentLoaded', this.onDOMContentLoaded)
    this.observer.disconnect()
  },
  computed: {
    videoHeight () {
      return this.videoWidth / Defaults.video.RATIO
    },
    signlyHeight () {
      return this.videoHeight + parseInt(Defaults.header.height[this.videoSize].slice(0, -2), 10)
    },
    logoHeight () {
      return Defaults.logo.height[this.videoSize]
    },
    widgetStyles () {
      return this.config?.widgetStyles?.join(' ') ?? ''
    }
  },
  watch: {
    requestingTranslation (newValue) {
      if (newValue) {
        this.submitTranslationRequest()
      }
    }
  },
  methods: {
    cleanPlayingBorder () {
      const playingBorderElement = document.getElementById('signly-playing-border')

      Object.assign(playingBorderElement.style, {
        display: 'none'
      })
    },
    handleResize () {
      this.window.width =
          window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      this.window.height =
          window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      this.adjustVideoHeight()
      this.updateElementDecorators()
    },
    handleUrlChange () {
      if (this.showHeader || (this.pageUrl === window.location.href)) {
        return
      }

      this.processDOMChange()
      this.pageUrl = window.location.href
    },
    onVideoResize () {
      this.adjustVideoHeight()
      localStorage.setItem('signlyVideoWidth', this.videoWidth.toString(10))
    },
    onDOMContentLoaded () {
      this.processDOMChange = this.debounce(() => this.setPageData())

      this.observer = new MutationObserver(this.mutate)

      const observerConfig = { subtree: true, childList: true }
      this.observer.observe(document.body, observerConfig)

      this.processDOMChange()
    },
    mutate (mutationsList, observer) {
      for (const mutation of mutationsList) {
        const isSignlyElement = mutation.target.id.includes('signly')

        if (!isSignlyElement && this.showContainer) {
          this.processDOMChange()
        }
      }
    },
    debounce (func, timeout = 500) {
      let timer
      return (...args) => {
        clearTimeout(timer)
        timer = setTimeout(() => { func.apply(this, args) }, timeout)
      }
    },
    adjustVideoHeight () {
      while ((this.signlyHeight > this.window.height) && (this.videoWidth > Defaults.video.MIN_WIDTH)) {
        this.videoWidth -= Defaults.video.WIDTH_INCR
      }
    },
    retryConnection () {
      this.currentPageUrl = null
      this.processDOMChange()
    },
    async setPageData () {
      if (!this.showContainer) {
        return
      }

      console.info('[signly] scrapePageData')

      this.translationRequestSent = false
      let updateAlert = false

      if (this.currentPageUrl !== window.location.href) {
        this.error = null
        updateAlert = true
        signly.removeDataSignlyAttributes()

        const { page, config, error } = await signly.getPageData(window.location.href)

        this.currentPageUrl = window.location.href

        this.page = page
        this.error = error
        this.config = config

        this.mediaBlocks = this.page ? this.page.mediaBlocks : []
      }

      signly.ignoreSpecificElements(this.config?.ignoreSelectors)
      this.textObjects = signly.getTextObjects(document)
      this.textSegments = this.textObjects.map((ve) => {
        return { rawText: ve.textSegment, index: ve.textIndex }
      })

      if (this.page && this.page.enabled && this.mediaBlocks.length) {
        this.decoratePageElements()
        this.findNewTextSegments()
      }

      // This will prevent the default video, inside the Widget, from restarting
      if (updateAlert) {
        this.updateAlert()
      }
    },
    findNewTextSegments () {
      const newTextSegments = []

      for (const textSegment of this.textSegments) {
        const mediaBlockExists = this.mediaBlocks.some(mediaBLock => textSegment.rawText.toLowerCase() === mediaBLock.normalizedText)
        if (!mediaBlockExists) {
          newTextSegments.push(textSegment)
        }
      }

      if (newTextSegments.length) {
        this.submitNewTextSegments(newTextSegments)
      }
    },
    async submitNewTextSegments (newTextSegments) {
      console.info('[signly] submitNewTextSegments')

      const pageData = {
        title: document.title,
        uri: window.location.href,
        mediaBlocks: newTextSegments,
        translated: false
      }

      const { page } = await signly.requestTranslation(pageData)

      if (page) {
        this.mediaBlocks = page.mediaBlocks
      }
    },
    updateAlert () {
      if (this.error === 404 || (this.page && !this.page.enabled)) {
        this.alert = Defaults.alerts.find(alert => alert.type === 'notTranslated')
        this.alert.message = this.$t('alerts.notTranslated.message')
        this.alert.action = this.$t('alerts.notTranslated.action')
        this.alert.videoUrl = this.$t('alerts.notTranslated.videoUrl')
      } else if (this.translationRequestSent) {
        this.alert = Defaults.alerts.find(alert => alert.type === 'requestSent')
        this.alert.message = this.$t('alerts.requestSent.message')
        this.alert.action = this.$t('alerts.requestSent.action')
        this.alert.videoUrl = this.$t('alerts.requestSent.videoUrl')
      } else if (this.page && this.page.enabled) {
        this.alert = Defaults.alerts.find(alert => alert.type === 'translated')
        this.alert.message = this.$t('alerts.translated.message')
        this.alert.videoUrl = this.$t('alerts.translated.videoUrl')
      } else {
        this.alert = Defaults.alerts.find(alert => alert.type === 'serverError')
        this.alert.message = this.$t('alerts.serverError.message')
        this.alert.action = this.$t('alerts.serverError.action')
        this.alert.videoUrl = this.$t('alerts.serverError.videoUrl')
      }

      this.videoUrl = this.alert.videoUrl

      this.showHeader = true
    },
    decoratePageElements () {
      const playButtonMessages = {
        playTranslation: this.$t('textSegment.playTranslation'),
        contentBeingTranslated: this.$t('textSegment.contentBeingTranslated'),
        untranslatedTextSegmentVideoUrl: this.$t('textSegment.untranslatedVideoUrl')
      }
      this.textObjects.forEach((to, index) => {
        const mediaBlockIndex = this.mediaBlocks.findIndex(mb => mb.normalizedText === to.textSegment.toLowerCase())
        const mediaBlock = this.mediaBlocks[mediaBlockIndex]
        signly.decorateTextElement(to.textElement, index, mediaBlock, this.playVideo, playButtonMessages)

        if (mediaBlock && mediaBlock.status === 'translated' && mediaBlock.video && mediaBlock.video.uri) {
          this.mappedMediaBlocks[index] = { element: to.textElement, videoUrl: mediaBlock.video.uri }
        }
      })
    },
    playVideo (payload) {
      this.alert.message = ''
      this.showContainer = true
      this.showMenu = false
      this.currentMediaBlockIndex = payload.elementIndex

      // Allows the same video to be played once again by triggering string mutation
      this.videoUrl = this.videoUrl === payload.videoUrl ? payload.videoUrl + ' ' : payload.videoUrl
      this.currentTextElement = payload.element
      this.updateElementDecorators()
    },
    updateElementDecorators () {
      if (!this.currentTextElement) {
        return
      }

      const playingBorderElement = document.getElementById('signly-playing-border')
      const borderElement = document.getElementById('signly-border')
      const buttonElement = document.getElementById('signly-button')

      Object.assign(playingBorderElement.style, { display: 'none' })

      const elementHeight = signly.getOffset(this.currentTextElement).height
      const elementLeft = signly.getOffset(this.currentTextElement).left
      const elementTop = signly.getOffset(this.currentTextElement).top
      const elementWidth = signly.getOffset(this.currentTextElement).width
      const elementOutlineOffset = window.getComputedStyle(this.currentTextElement).outlineOffset

      Object.assign(playingBorderElement.style, {
        width: elementWidth + 'px',
        height: elementHeight + 'px',
        left: elementLeft + 'px',
        top: elementTop + 'px',
        display: 'block',
        outlineOffset: elementOutlineOffset
      })

      Object.assign(buttonElement.style, { display: 'none' })
      Object.assign(borderElement.style, { display: 'none' })
    },
    async submitTranslationRequest () {
      this.error = null
      this.translationRequestSent = false
      this.requestingTranslation = true

      const pageData = {
        title: document.title,
        uri: window.location.href,
        mediaBlocks: this.textSegments,
        translated: false
      }

      const { page, error } = await signly.requestTranslation(pageData)

      this.error = error
      this.translationRequestSent = !!page
      this.page = page
      this.requestingTranslation = false

      this.updateAlert()
    },
    onLogoClick () {
      this.showContainer = true
      window.localStorage.setItem('showSignly', 'true')
      this.setPageData()
    },
    onCloseClick () {
      this.showContainer = false
      window.localStorage.setItem('showSignly', 'false')
      signly.removeDataSignlyAttributes()
      this.cleanPlayingBorder()
    },
    drag (element) {
      let currentPositionX = 0
      let currentPositionY = 0
      let newPositionX = 0
      let newPositionY = 0

      element.onmousedown = dragMouseDown
      document.onkeydown = keyboardKeyDown

      function keyboardKeyDown () {
        element.focus()

        currentPositionX = element.offsetParent?.offsetHeight - element.offsetTop - element.offsetHeight + newPositionY
        currentPositionY = element.offsetParent?.offsetWidth - element.offsetLeft - element.offsetWidth + newPositionX

        document.onkeydown = keyboardMove

        return false
      }

      function keyboardMove (evt) {
        const xMax = window.innerWidth - element.offsetWidth - 8
        const yMax = window.innerHeight - element.offsetHeight

        let bottom = element.offsetParent?.offsetHeight - element.offsetTop - element.offsetHeight
        let right = element.offsetParent?.offsetWidth - element.offsetLeft - element.offsetWidth

        switch (evt.keyCode) {
          case 37:
            right += 20
            break
          case 38:
            bottom += 20
            break
          case 39:
            right -= 20
            break
          case 40:
            bottom -= 20
            break
          default:
            return true
        }

        element.style.bottom = `${Math.max(0, Math.min(bottom, yMax))}px`
        element.style.right = `${Math.max(0, Math.min(right, xMax))}px`

        return false
      }

      function dragMouseDown (evt) {
        evt.preventDefault()
        currentPositionX = evt.clientX
        currentPositionY = evt.clientY

        document.onmouseup = closeDragElement
        document.onmousemove = elementDrag
      }

      function elementDrag (evt) {
        evt.preventDefault()
        setNewPosition(evt)
      }

      function setNewPosition (evt) {
        evt.preventDefault()
        newPositionX = currentPositionX - evt.clientX
        newPositionY = currentPositionY - evt.clientY

        const xMax = window.innerWidth - element.offsetWidth - 8
        const yMax = window.innerHeight - element.offsetHeight

        const bottom = element.offsetParent?.offsetHeight - element.offsetTop - element.offsetHeight + newPositionY
        const right = element.offsetParent?.offsetWidth - element.offsetLeft - element.offsetWidth + newPositionX

        element.style.bottom = `${Math.max(0, Math.min(bottom, yMax))}px`
        currentPositionY = evt.clientY

        element.style.right = `${Math.max(0, Math.min(right, xMax))}px`
        currentPositionX = evt.clientX
      }

      function closeDragElement () {
        document.onmouseup = null
        document.onmousemove = null
      }
    }
  }
}
</script>
